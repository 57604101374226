.keyword-list,
.keyword-parade {
  // margin: 3rem 0;
  font-family: var(--monospace-font-family);
}

.keyword {
  &:hover {
    background-color: var(--highlight-color);
    cursor: pointer;
  }
  &.highlighted {
    background-color: var(--highlight-color);
  }
  background-color: var(--secondary-color-low-contrast);
  color: #ddd;
  font-size: 1.25rem;
  display: inline-block;
  margin: 0.5rem 0.35rem 0 0;
  padding: 0.25rem 0.5rem;
  // border: 1px solid var(--secondary-color-low-contrast);
  border-radius: 0.5rem;
}

.keyword-parade {
  height: 15rem;
  // overflow: hidden;
  .keyword {
    opacity: 0.7;

    &.highlighted {
      opacity: 1;
    }
  }
}
