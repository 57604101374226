@media screen and (max-width: 930px) {
  header {
    display: block;
  }
}

@media screen and (min-width: 576px) {
  .header-content {
    img {
      max-width: 30rem;
      max-height: 30rem;
      padding: 0 2rem;
    }
  }
  main p:first-of-type {
    margin-top: 0;
  }
  .main-container {
    margin-top: 8rem;
  }
}

@media screen and (min-width: 768px) {
}
