.search-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .clear-filters-control {
    .clear-filters-label {
      font-size: 1.25rem;
      margin-right: 1rem;
    }
  }
}
