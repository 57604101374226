.timeline {
  position: absolute;
  width: 100%;
  // max-width: 20rem;
  height: 100%;
  overflow: hidden;
  background-color: rgba(40, 40, 40, 0.7);
  // border: 1px solid red;

  .month-tick {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: 1.2rem;
    color: #aaa;
    // border-bottom: 1px solid green;
    &.month-Jan {
      opacity: 0;
    }
  }

  .year-container {
    width: 100%;
    position: absolute;
    // border-bottom: 1px solid gray;

    .year-label {
      color: white;
      font-size: 2rem;
      font-weight: 900;
      transform: translate(-50%, -50%);
      position: absolute;
      left: 50%;
    }
    .year-line {
      width: 25%;
      height: 1px;
      background-color: rgba(240, 240, 240, 0.4);
      position: absolute;
      bottom: 0;
    }
    .year-line-left {
      left: 0;
    }
    .year-line-right {
      right: 0;
    }
  }

  .ranges {
    position: absolute;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .date-range {
    width: 1.5rem;
    // background-color: #777;
    border-radius: 0.25rem;
    position: absolute;

    .timestamp {
      font-size: 1rem;
      position: absolute;
      left: 1.5rem;
      width: 10rem;
    }
  }
}
