@use 'sass:color';

.history-view-container {
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s;

  &.initialized {
    opacity: 1;
  }
}

.history-view {
  width: 100%;
  transform: translateX(0%);
}

// .history-list-header {
//   position: absolute;
//   display: flex;
//   top: 0;
//   left: 0;
//   font-size: 2rem;
//   font-weight: 700;
// }

.job-list,
.project-list,
.timeline-view {
  position: absolute;
  left: 0;
  top: 8rem;
  width: 100%;
  height: 100%;
}

.history-view-container.initialized {
  .job-list,
  .project-list,
  .timeline-view {
    transition: all 0.5s;
  }
}

.job-list {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.project-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.timeline-view {
}

.years-experience {
  font-size: 2rem;
  color: var(--primary-color-inverted);
  .duration {
    font-weight: 700;
    margin: 0 1rem;
  }
}

.card {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  margin-bottom: 3rem;
  padding: 2rem;
  opacity: 1;

  // transition: all 0.5s;

  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 1rem;

  &.highlighted {
    // background: linear-gradient(rgba(0, 0, 0, 0) 0%, var(--highlight-color));
    // background: linear-gradient(in hsl longer hue, rgba(0, 0, 0, 0) 0%, var(--highlight-color));

    // in hsl longer hue, blue, red
  }

  &.med {
    padding: 1.5rem;
  }

  &.sml {
    padding: 0.8rem;
  }

  .card-top,
  .card-bottom {
    display: flex;
    width: 100%;
  }
  .card-bottom {
    margin-top: 1rem;
  }

  &.sml .card-bottom {
    margin-top: 0.5rem;
  }
  &.tny .card-bottom {
    margin-top: 0;
  }

  &.tny .card-top {
    flex-direction: column;
    align-items: center;
  }

  .card-content-container {
    width: 100%;
    overflow: auto;
    padding: 0;
    // border: 1px solid black;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .headline {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  h3 {
    margin-top: 0;
    font-weight: 700;
  }
  &.med h3 {
    font-size: 1.6rem;
  }
  &.sml h3 {
    font-size: 1.4rem;
  }

  &.tny h3 {
    font-size: 1.3rem;
  }

  .detail {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    width: 100%;
    color: var(--text-color-secondary);
    font-size: 1.4rem;
  }

  &.med .detail {
    font-size: 1.2rem;
  }

  &.med .detail-secondary {
    display: none;
  }

  &.tny .detail {
    display: none;
  }

  .description {
    // font-size: 1.8rem;
    margin: 1rem 0;
    max-width: 85%;
  }

  &.med {
    .description {
      display: none;
    }
  }

  &.open .description {
    font-weight: 700;
  }

  &.sml {
    .keyword-list {
      display: none;
    }
  }
  .keyword-summary {
    display: none;
  }
  &.sml .keyword-summary {
    display: block;
    font-size: 1.3rem;
  }

  .image-container {
    margin-right: 2rem;
    max-width: 10rem;
    // background-color: ;
    .image-bg {
      width: 10rem;
      height: 10rem;
      object-fit: cover;
      background-size: cover;
      background-position: 50%;
      &.contain {
        object-fit: contain;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    img {
      display: none;
      max-width: 10rem;
      height: auto;
    }
  }

  .date-range-description {
    font-size: 1.3rem;
  }

  &.med .date-range-description {
    display: none;
  }

  &.med .image-container {
    .image-bg {
      width: 5rem;
      height: 5rem;
    }
    margin-right: 1rem;
  }

  &.tny .image-container {
    .image-bg {
      height: 10rem;
      width: 10rem;
    }
    display: flex;
    justify-content: center;
    margin-right: 0;
    margin-bottom: 0.5rem;
  }

  &.med .keyword {
    font-size: 1rem;
  }

  .card-more-link {
    margin-top: 0;
    font-size: 1.4rem;
    color: var(--link-color);
    cursor: pointer;
    span {
      margin-right: 1rem;
    }
    :hover {
      color: var(--link-color-hover);
      svg {
        path {
          stroke: var(--link-color-hover);
        }
      }
    }
    svg {
      transform: translateY(-2px) rotate(-90deg);
      path {
        stroke: var(--link-color);
      }
    }
  }

  &.med .card-more-link {
    margin-bottom: 0;
  }
  &.sml .card-more-link {
    display: none;
  }

  &.open {
    z-index: 3000;
  }
  &.faded {
    opacity: 0.3;
  }
  ul.bullet-points {
    padding-left: 1.5rem;
    li {
      margin-bottom: 0.6rem;
    }
  }
}
