@use 'sass:color';

:root {
  --bg-color: #333;
  --text-color-dol: #414141;
  --text-color-lod: #d3d3d3;
  --text-color-secondary: #605365;
  --text-color-header: #62689e;
  --base-font-size: 1.6rem;
  --base-font-family: 'mundial', Helvetica, Arial, sans-serif;
  // --base-font-family:
  // --heading-font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  // --heading-font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  --heading-font-family: 'europa', -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', 'Noto Sans', 'Liberation Sans',
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --monospace-font-family: Menlo, Consolas, Monaco, 'Liberation Mono', monospace;
  // --link-color: rgb(221, 199, 199);
  --link-color: rgb(173, 120, 70);
  --link-hover-color: rgb(215, 213, 80);
  --primary-color: rgb(180, 180, 232);
  --primary-color-inverted: rgb(223, 223, 234);
  --secondary-color: rgb(209, 245, 180);
  --secondary-color-low-contrast: #687365;
  --highlight-color: #584f6f;
  --max-content-width: 100rem;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}
body {
  background-color: var(--bg-color);
  color: var(--text-color-dol);
  font-family: var(--base-font-family);
  font-size: var(--base-font-size);
}
a {
  color: var(--link-color);
  text-decoration: none;
  &:hover {
    color: var(--link-hover-color);
  }
}
h1,
h2,
h3 {
  // text-transform: uppercase;
  color: var(--text-color-header);
  font-family: var(--heading-font-family);
  font-weight: 500;
  margin-bottom: 0.25rem;

  & + ul,
  & + p {
    margin-top: 0.25rem;
  }
}
