@use './breakpoints.scss' as *;

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 3rem;
  overflow: hidden;
}

main {
  width: 100%;
}

.mobile-disclaimer {
  display: flex;
  justify-content: center;
  height: 100vh;
  p {
    color: var(--text-color-lod);
    font-size: 2rem;
    padding: 4rem;
    margin-top: 20%;
  }
}

.site-container {
  .background,
  header,
  .fixable-header {
    transition: opacity 0.5s;
  }
  &.site-mode-bg-only {
    .background {
      opacity: 1;
    }
    header,
    .main-container {
      opacity: 0;
    }
    .fixable-header {
    }
  }
  &.site-mode-portfolio-only {
    .background {
      opacity: 0.2;
    }
    header,
    .fixable-header,
    .main-container {
      opacity: 1;
    }
  }
  &.site-mode-both {
    .background {
      opacity: 0.8;
    }
    header,
    .fixable-header,
    .main-container {
      opacity: 1;
    }
  }
}

.background {
  z-index: -1;
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: black;
}
