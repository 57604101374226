$bgColor: rgba(255, 255, 255, 0.5);

.control-panel-container {
  position: relative;
  width: auto;
  &.visible {
    width: 100%;
  }
}

.control-panel-button {
  background-color: $bgColor;
  border-radius: 1rem;
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
}

.control-panel-container.visible .control-panel-button {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.control-panel-container.visible .control-panel {
  opacity: 1;
  pointer-events: all;
}

.control-panel {
  font-size: 1.3rem;
  color: #222;
  transition: all 0.5s;
  opacity: 0;
  pointer-events: none;

  position: absolute;
  top: 3rem;
  right: 0;
  z-index: 5000;

  display: grid;
  gap: 1rem;
  grid-template-rows: fr1;

  background-color: $bgColor;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 1rem;
  border-top-right-radius: 0;

  padding: 2rem;

  .cp-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 1.5rem;
    h2 {
      padding: 0;
      margin-top: 0;
    }
  }

  .cp-section {
    .control-group {
      display: grid;
      gap: 0.25rem;
      .group-name {
        font-weight: 700;
      }
      .control {
        margin-left: 1rem;
      }
    }

    .control {
      display: grid;
      gap: 1rem;
      grid-template-columns: 6rem 2rem 12rem 3rem;
      grid-template-areas: 'name enable input value';
      align-items: center;
    }
    .control-title {
      grid-area: name;
    }
    .control-enable {
      grid-area: enable;
    }
    .control-input {
      grid-area: input;
    }
    .control-value {
      grid-area: value;
    }

    control-title.group-item {
      margin-left: 0.5rem;
    }
    input {
      // width: 120px;
    }
  }
}

.switch {
  border-radius: 3rem;
  border: 3px solid #555;
  background-color: hsla(0, 0, 80, 0.9);
  display: flex;
  flex-direction: row;
  padding: 4px;
  align-items: center;
  min-width: 8rem;

  justify-content: flex-start;

  &.disabled {
    background-color: hsla(0, 0, 80, 0.5);
    .switch-knob {
      opacity: 0.5;
    }
  }

  .switch-knob {
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
    transition: all 0.5sec;
  }
  .standard-knob {
    background-color: white;
  }

  &.small {
    min-width: 6rem;
    .switch-knob {
      height: 2rem;
      width: 2rem;
    }
  }

  &.on {
    justify-content: flex-end;
    .switch-knob {
      // transform: rotate(180deg);
    }
  }
}

.site-mode-switches {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;

  .bg-switch .bg-loader {
    transform: scale(0.3);
    height: 30px;
    width: 30px;
    transform-origin: 0 0;

    &.small {
      height: 20px;
      width: 20px;
    }
  }

  .bg-switch,
  .pf-switch {
    margin: 1rem 0 0;
    max-width: 10rem;

    &.small {
      max-width: 6rem;
    }
  }

  &.horizontal {
    .bg-switch,
    .pf-switch {
      margin-top: 0;
    }
    .bg-switch {
      margin-left: 1rem;
      margin-right: 3rem; // for the controls??
    }
    flex-direction: row;
    justify-content: flex-end;
  }

  .pf-switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .bg-switch {
    position: relative;
    display: grid;
    column-gap: 1rem;
    row-gap: 0.3rem;
    grid-template-columns: 8rem 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'switch acc';
    align-items: center;

    &.small {
      grid-template-columns: 6rem 1fr;
    }

    .switch {
      grid-area: switch;
    }
    .bg-loader,
    .bg-controls {
      grid-area: acc;
    }
    .bg-fps {
      position: absolute;
      top: 100%;
      left: 50%;
      width: 100%;
      transform: translateX(-50%);
      font-size: 1rem;
      color: #aaa;
      text-align: center;
    }
  }
}
