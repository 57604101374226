.fixed-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 3rem;
  background: rgba(15, 15, 15, 1);
  opacity: 0;

  &.visible {
    opacity: 1;
  }
  transition: opacity 1s;
}
