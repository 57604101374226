@use './breakpoints.scss' as *;

header {
  padding: 4rem 4rem 0;
  font-size: 1.5rem;

  p:first-of-type {
    margin-top: 0;
  }

  // background-color: gradientrgba(40, 40, 40, 0.7);
  // background: linear-gradient(180deg, rgba(2, 0, 36, 0) 0%, rgba(15, 15, 15, 0.7) 100%);

  color: var(--text-color-lod);
  h1,
  h2,
  h3 {
    color: var(--text-color-lod);
  }

  p {
    font-size: 2rem;
  }

  // position: static;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .brand-name {
    color: white;
    margin: 0;
  }

  .subhead {
    font-size: 1.8rem;
    font-weight: 700;
    color: var(--text-color-secondary);
  }
}

.stickyable-header {
  z-index: 4000;
  background-color: rgba(15, 15, 15, 1);
  width: 100vw;

  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 2fr 1fr 2fr 1fr 2fr;
  grid-template-areas: 'logo . menu . controls';
  align-items: center;

  gap: 1rem;

  padding: 1rem 3rem;
  // transition: background-color 1s;
  &.sticky {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(15, 15, 15, 1);
    .sticky-header-lockup {
      opacity: 1;
    }
    .sticky-header-controls {
      opacity: 1;
    }
  }

  .sticky-header-logo {
    grid-area: logo;
  }
  .sticky-header-controls {
    grid-area: controls;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .sticky-header-lockup {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    opacity: 1;
    transition: opacity 1s;
    .wippy-wip-wip {
      margin: 0 0 0 2rem;
    }
    h1 {
      margin: 0;
      font-size: 1.5rem;
      color: white;
    }
    .sticky-header-logo-img {
      // width: 100px;
      width: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100%;
      }
    }
  }

  .sticky-header-controls {
    opacity: 1;
    transition: opacity 1s;
    // transform: scale(0.8);
  }

  .sticky-header-menu {
    grid-area: menu;
    width: 100%;
    display: grid;
    position: relative;
    opacity: 1;
    transition: opacity 1s;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;

    &.initialized {
      opacity: 1;
    }
  }

  .menu-item {
    font-size: 2rem;
    font-weight: 700;
  }
  .menu-item {
    opacity: 1;
    transition: opacity 1s;
    // position: absolute;
    display: flex;
    // top: 0;
    // left: 0;
  }
  &.sticky .menu-item {
    opacity: 1;
  }
}

.controls-header {
  display: flex;
  justify-content: space-between;
}
.clear-keywords-control {
  .clear-keywords-label {
    color: #bbb;
  }
  button {
    border-radius: 50%;
    border: none;
    margin-left: 0.5rem;
    background-color: #aaa;
  }
}

.header-content-container {
  // height: 100vh;
  max-width: var(--max-content-width);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  position: relative;
}

.header-content {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: -10%;
  .logo {
    max-width: 100%;
    border-radius: 50%;
  }

  .keyword-parade-container {
    // height: 20rem;
    .keyword-parade-prefix {
      font-size: 1.5rem;
    }
    .duration {
      font-size: 2rem;
      margin: 3rem 0;
    }
  }
}

.greeting-image {
  max-width: 100%;
  border-radius: 50%;
  padding: 3rem;

  .headshot {
    border-radius: 50%;
    margin: 0 2rem 2rem 0;
  }
}

.intro {
  display: flex;
  align-items: center;
  margin-bottom: 4rem;
}

.experience,
.bio {
  color: var(--text-color-lod);
  max-width: 80rem;
}

.experience {
  strong {
    font-size: 2rem;
  }
}

.ive-built {
  font-size: 6rem;
  strong {
    font-weight: 100;
  }

  margin-bottom: 5rem;
}

.bio {
  margin-top: 2rem;
  font-size: 1.8rem;
  color: var(--text-color-lod);
  max-width: 80rem;

  .greeting-big {
    font-size: 5rem;
    font-weight: 100;
    color: white;
    margin-bottom: 1rem;
  }
  .greeting {
    margin-top: 0rem;
    font-size: 3rem;
    color: white;
  }

  .desc {
    font-size: 2rem;
    strong {
      color: var(--primary-color);
    }
  }
}

.corner-wip {
  color: rgb(255, 45, 45);
  position: absolute;
  top: 0;
  right: 0;
  font-weight: 700;
  font-size: 3.5rem;
  border: 1px solid red;
  padding: 1rem;
  transform-origin: top right;
}

.wippy-wip-wip {
  color: rgb(255, 45, 45);
}

.wippy-container {
  overflow: hidden;
  margin: 0 0 0 2rem;
  width: 100%;
  p {
    color: rgb(255, 45, 45);
    font-size: 1.3rem;
    line-height: 1.1rem;
    margin: 0;
  }
}

@media screen and (max-width: $tablets) {
  .sticky-header-center {
    display: none;
  }
}

@media screen and (max-width: $phones) {
  main {
    width: 100%;
  }
}
